<template>
    <div>
        <b-modal ref="templateModal" centered header-bg-variant="primary" @ok="saveTemplate" :ok-disabled="!state">
            <div slot="modal-header" class="w-100">
                <h2 class="text-center">{{ $t('deviceEdit.saveTemplate') }}</h2>
            </div>

            <b-form-group id="fieldset1" description="" :label="$t('deviceEdit.enterTemplateName')" label-for="name-input"
                          :invalid-feedback="invalidFeedback" :valid-feedback="validFeedback" f :state="state">
                <b-form-input id="name-input" :state="state" v-model.trim="name" @keypress.enter.native="pressedEnter"/>
            </b-form-group>

            <div slot="modal-ok">{{ $t('general.confirm') }}</div>
            <div slot="modal-cancel">{{ $t('general.cancel') }}</div>
        </b-modal>

        <b-modal ref="successModal" centered header-bg-variant="primary" ok-only>
            <div slot="modal-header" class="w-100">
                <h2 class="text-center">{{ $t('general.success') }}</h2>
            </div>

            {{ $t('deviceEdit.templateSavedSuccessfully') }}
        </b-modal>

        <b-modal ref="maxLimitModal" centered header-bg-variant="primary" ok-only>
            <div slot="modal-header" class="w-100">
                <h2 class="text-center">{{ $t('deviceEdit.maxLimit') }}</h2>
            </div>

            {{ $t('deviceEdit.noMoreThan20') }}
        </b-modal>

        <b-modal ref="failModal" centered header-bg-variant="danger" ok-only>
            <div slot="modal-header" class="w-100">
                <h2 class="text-center">{{ $t('deviceEdit.fail') }}</h2>
            </div>

            {{ $t('deviceEdit.couldNotBeSaved') }}
        </b-modal>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "SaveTemplate",

        mounted() {
            this.name = "Template " + this.getDeviceName(this.$route.params.id);
            this.$root.$on('show-save-modal', () => {
                this.$refs.templateModal.show()
            });
        },

        destroyed() {
            this.$root.$off('show-save-modal');
        },

        computed: {
            ...mapGetters({
                getDeviceName: 'virtualDevice/deviceName'
            }),

            state() {
                return this.name.length >= 4 && this.name.length <= 20
            },

            invalidFeedback() {
                if (this.name.length > 4 && this.name.length <= 20) {
                    return '';
                } else if (this.name.length > 20) {
                    return this.$t('deviceEdit.templateNameNoMoreThan20Chars');
                } else if (this.name.length > 0) {
                    return this.$t('deviceEdit.atLeast4Chars');
                } else {
                    return this.$t('deviceEdit.notEmpty');
                }
            },

            validFeedback() {
                return this.state === true ? this.$t('deviceEdit.ready') : ''
            }
        },

        data() {
            return {
                name: ""
            }
        },

        methods: {
            pressedEnter() {
                if (this.state) {
                    this.$refs.templateModal.hide();
                    this.saveTemplate();
                }
            },

            saveTemplate() {
                let timezoneOffset = new Date().getTimezoneOffset();

                this.$axios({
                    method: 'post',
                    url: '/save-parameters-template',
                    data: {
                        data: {
                            id: this.$route.params.id,
                            name: this.name,
                            timezoneOffset: timezoneOffset
                        }
                    },
                    timeout: 10000
                }).then((response) => {
                    if (response.data.msg === "success") {
                        this.$refs.successModal.show();
                    } else {
                        this.$refs.maxLimitModal.show();
                    }
                }).catch(() => {
                    this.$refs.failModal.show();
                });
            }
        }
    }
</script>

<style scoped>

</style>