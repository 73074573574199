<template>
    <b-row class="py-2">
        <b-col cols="2" class="d-flex align-items-center">
            <b-btn @click="edit()" class="setpoint" v-bind:style="{ 'border-color': borderColor }">
                {{ temperature }}
            </b-btn>
        </b-col>
        <b-col cols="4" class="d-flex align-items-center pr-0">
            <date-picker v-model="startTime" lang="en" type="time" :format="'HH:mm'" value-type="format" :minute-step=15
                         :disabled="startTime === '00:00'" :editable="false" :clearable="false" confirm>
                <template slot="calendar-icon"><span class="icon-clock"></span></template>
            </date-picker>
        </b-col>
        <b-col cols="4" class="d-flex align-items-center pr-0">
            <date-picker v-model="endTime" lang="en" type="time" :format="endTime === '24:00' ? endTime : 'HH:mm'" value-type="format" :minute-step=15
                         :disabled="endTime === '24:00'" :editable="false" :clearable="false" confirm>
                <template slot="calendar-icon"><span class="icon-clock"></span></template>
            </date-picker>
        </b-col>
        <b-col cols="2" class="d-flex align-items-center">
            <span style="font-size: 18px">
                <b-btn class="mx-1 float-right small-edit-button" @click="edit()">
                    <i class="icon-pencil small-edit-icon"></i>
                </b-btn>
            </span>
        </b-col>
    </b-row>
</template>

<script>
    import {temp2Color} from '@/utilities/interval.utility';
    import DatePicker from 'vue2-datepicker'
    import {mapGetters} from 'vuex';

    export default {
        name: "MobileInterval",

        components: {
            DatePicker
        },

        props: [
            "id",
            "start",
            "end",
            "value",
            "day",
            "moduleNumber"
        ],

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            borderColor: function () {
                if (this.isTypeOnOff) {
                    return this.value === 999 ? "rgb(220, 220, 220)" : "rgb(244, 185, 66)";
                } else {
                    return temp2Color(this.value);
                }
            },

            isTypeOnOff: function () {
                if (this.moduleNumber === 0) {
                    return true;
                } else if (this.moduleNumber === 1) {
                    return parseInt(this.parameters.p_78.value) === 3;
                } else if (this.moduleNumber === 2) {
                    return parseInt(this.parameters.p_81.value) === 3;
                } else if (this.moduleNumber === 3) {
                    return parseInt(this.parameters.p_84.value) === 3;
                }
            },

            temperature: function () {
                if (this.isTypeOnOff) {
                    return this.value === 999 ? "Off" : "On";
                } else {
                    return this.value === 999 ? "Off" : this.value + '°';
                }
            },

            startTime: {
                get: function () {
                    return this.start;
                },

                set: function (newValue) {
                    this.$root.$emit("update:start-time", newValue, this.id, this.day, this.moduleNumber);
                }
            },

            endTime: {
                get: function () {
                    return this.end;
                },

                set: function (newValue) {
                    this.$root.$emit("update:end-time", newValue, this.id, this.day, this.moduleNumber);
                }
            }
        },

        methods: {
            edit() {
                this.$root.$emit('edit-interval', this.id, this.day, this.value, this.moduleNumber);
            }
        }
    }
</script>

<style>
    a.mx-time-header {
        visibility: hidden;
    }

    a.mx-time-header:after {
        visibility: visible;
        margin-left: -55px;
        content: 'Time';
    }

    .setpoint {
        font-size: 13px;
        padding: 0;
        border-radius: 50%;
        min-width: 40px;
        min-height: 40px;
        border-color: coral;
        color: black;
        background: white;
        outline: none;
    }

    .setpoint:focus {
        color: black;
        box-shadow: none;
        background: white !important;
    }

    .setpoint:hover {
        color: black;
        box-shadow: none;
        background: white !important;
    }

    .small-edit-button {
        padding: 0rem 0.3rem;
        box-shadow: none;
        background: white;
        border: none;
        outline: none !important;
    }

    .small-edit-button:focus {
        box-shadow: none;
        background: white !important;
        border-color: white !important;
    }

    .small-edit-button:hover {
        box-shadow: none;
        background: white !important;
        border-color: white !important;
    }

    .small-edit-button:after {
        border: none !important;
        outline: none !important;
    }

    .small-edit-icon {
        color: #20a8d8;
    }
</style>
