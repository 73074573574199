<template>
    <div>
        <strong>{{ $t('deviceEdit.resetEnergyData') }}</strong>
        <hr>
        <b-col cols="12" sm="12" md="7" lg="6" xl="5" class="p-0">
            <p>
                {{ $t('deviceEdit.deleteDataProducedByDevice') }}
            </p>
        </b-col>

        <div class="pb-5">
            <b-button class="float-left" variant="warning" :disabled="resetting" v-b-modal.resetEnergyModal>{{ $t('deviceEdit.reset') }}</b-button>
            <spinner v-if="resetting" size="26" class="float-left" line-fg-color="var(--warning)" style="margin-top: 0.3em; margin-left: 0.6em;"/>

            <div v-if="failed" id="warning" class="float-left" style="margin-top: 0.3em; margin-left: 0.6em;">
                <b-btn id="energy_reset_failed" class="warning-button shadow-none">
                    <i class="fa fa-warning warning-icon"></i>
                </b-btn>
                <b-tooltip :target="'energy_reset_failed'" title="Communication error, check if device is online." placement="right" triggers="click">
                </b-tooltip>
            </div>

        </div>

        <b-modal id="resetEnergyModal" centered header-bg-variant="warning" @ok="resetEnergy">

            <div slot="modal-header" class="w-100">
                <h2 class="text-center">{{ $t('deviceEdit.deleteEnergyData') }}</h2>
            </div>

            <b-form-group>
                {{ $t('deviceEdit.doYouWantToDeleteData') }}
            </b-form-group>

            <div slot="modal-ok">{{ $t('general.confirm') }}</div>
            <div slot="modal-cancel">{{ $t('general.cancel') }}</div>
        </b-modal>
    </div>
</template>

<script>
    import Spinner from 'vue-simple-spinner';

    export default {
        name: "ResetEnergyData",

        components: {
            Spinner
        },

        data() {
            return {
                retryInterval: null,
                numberOfRetries: 0,
                resetting: false,
                failed: false
            }
        },

        methods: {
            resetEnergy() {
                clearInterval(this.retryInterval);
                this.sendResetRequest();
                this.failed = false;
                this.resetting = true;

                this.retryInterval = setInterval(() => {
                    this.numberOfRetries++;

                    if (this.numberOfRetries <= 5) {
                        this.sendResetRequest();
                    } else {
                        clearInterval(this.retryInterval);
                        this.resetting = false;
                        this.failed = true;
                    }
                }, 5000);
            },

            sendResetRequest() {
                this.$axios.get('/reset-energy-data', {
                    params: {
                        id: this.$route.params.id
                    }
                }).then(response => {
                    if (response.data == '0') {
                        clearInterval(this.retryInterval);
                        this.resetting = false;
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    }
</script>

<style scoped>
    .warning-button {
        color: white;
        background-color: white;
        border-color: white;
        padding: 0;
        margin: 0;
    }

    .warning-button:focus {
        background: white !important;
        border-color: white !important;
    }

    .warning-icon {
        color: orange;
        font-size: 22px;
        padding: 0;
    }
</style>