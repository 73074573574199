<template>
    <div>
        <div v-if="shouldShow('System')">
            <strong>{{ $t('deviceEdit.system') }}</strong>
            <hr>
            <br>
            <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["System"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
        </div>

        <div v-if="shouldShow('Collector')">
            <br><strong>{{ $t('deviceEdit.collector') }}</strong>
            <hr>
            <br>
            <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Collector"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
        </div>

        <div v-if="shouldShow('Recooling')">
            <br><strong>{{ $t('deviceEdit.recooling') }}</strong>
            <hr>
            <br>
            <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Recooling"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
        </div>

        <div v-if="shouldShow('Frost')">
            <br><strong>{{ $t('deviceEdit.frost') }}</strong>
            <hr>
            <br>
            <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Frost"].indexOf(key) > -1
                           && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
        </div>

        <div v-if="shouldShow('Seizing')">
            <br><strong>{{ $t('deviceEdit.seizing') }}</strong>
            <hr>
            <br>
            <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Seizing"].indexOf(key) > -1'
                       :param-key="key" :param-value="value"></Parameter>
        </div>

        <div v-if="shouldShow('Storage Alarm')">
            <br><strong>{{ $t('deviceEdit.storageAlarm') }}</strong>
            <hr>
            <br>
            <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Storage Alarm"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
        </div>
    </div>
</template>

<script>
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

    export default {
        name: "Protections",

        components: {
            Parameter
        },

        props: [
            'parameters',
            'categoriesLookup',
            'shouldShow'
        ],

        methods: {
            /**
             * Checks if a parameter should render or not
             * @param key
             * @returns {boolean}
             */
            shouldRenderParameter(key) {
                if (key === 'p_11' || key === 'p_12') return parseInt(this.parameters.p_10.value) !== 0; //Don't display parameter if protection isn't active
                else if (key === 'p_14' || key === 'p_15' || key === 'p_16') return parseInt(this.parameters.p_13.value) !== 0;
                else if (key === 'p_18') return parseInt(this.parameters.p_17.value) !== 0;
                else if (key === 'p_20' || key === 'p_21') return parseInt(this.parameters.p_19.value) !== 0;
                else if (key === 'p_26') return parseInt(this.parameters.p_25.value) !== 0;
                else return true;
            }
        }
    }
</script>

<style scoped>

</style>