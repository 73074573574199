<template>
    <div>
        <div v-if="shouldShow('Root Settings')" class="pb-3">
            <Parameter v-for="(value, key) in parameters" :key="key" v-if="categoriesLookup['Settings']['Root Settings'].indexOf(key) > -1 && shouldRenderParameter(key)"
                       :param-key="key" :param-value="value"></Parameter>
        </div>

        <div v-if="shouldShow('Heat Transfer')">
            <strong>Heat Transfer</strong>
            <hr>
            <br>
            <Parameter v-for="(value, key) in parameters" :key="key" v-if="categoriesLookup['Settings']['Heat Transfer'].indexOf(key) > -1"
                       :param-key="key" :param-value="value"></Parameter>
        </div>

        <div v-if="shouldShow('Heat Pump')">
            <strong>Heat Pump</strong>
            <hr>
            <br>
            <Parameter v-if="categoriesLookup['Settings']['Heat Pump'].indexOf('p_68') > -1" :key="'p_68'" :param-key="'p_68'"
                       :param-value="parameters['p_68']"></Parameter>

            <Parameter v-if="categoriesLookup['Settings']['Heat Pump'].indexOf('p_69') > -1" :key="'p_69'" :param-key="'p_69'"
                       :param-value="parameters['p_69']"></Parameter>

            <div v-if="parseInt(parameters['p_68'].value) === 2" class="py-3">
                <div class="py-3">
                    <b-row>
                        <b-col>
                            <strong class="float-left mr-2">Schedule</strong>
                            <spinner v-if="showScheduleSpinner" size="20" class="float-left"></spinner>
                        </b-col>
                    </b-row>
                    <hr>
                    <mobile-schedule v-if="shouldRenderMobileSchedule" :module-number="0"></mobile-schedule>
                    <desktop-schedule v-else :module-number="0"></desktop-schedule>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const MobileSchedule = () => import('@/components/virtual-device/edit/schedules/MobileScheduleV2');
    const DesktopSchedule = () => import('@/components/virtual-device/edit/schedules/DesktopScheduleV2');
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";
    import Spinner from 'vue-simple-spinner';

    export default {
        name: "Settings",

        components: {
            MobileSchedule,
            DesktopSchedule,
            Parameter,
            Spinner
        },

        props: [
            'parameters',
            'categoriesLookup',
            'shouldShow',
            'shouldRenderMobileSchedule'
        ],

        data() {
            return {
                showScheduleSpinner: false
            }
        },

        methods: {
            shouldRenderParameter(key) {
                if (key === 'p_259' || key === 'p_260') return this.parameters['p_258'].value == 0;
                else return true;
            }
        },

        created() {
            this.$root.$on('show-schedule-spinner', (value) => {
                this.showScheduleSpinner = value;
            });
        },

        destroyed() {
            this.$root.$off('show-schedule-spinner');
        }
    }
</script>

<style scoped>

</style>