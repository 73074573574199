<template>
    <div>
        <div v-if="shouldShow('Root Setup')">
            <Parameter v-for="(value, key) in parameters" :key="key"
                       v-if='categoriesLookup["Setup"]["Root Setup"].indexOf(key) > -1' :param-key="key" :param-value="value"/>
        </div>

        <div v-if="shouldShow('Sensors Config')">
            <br><strong>{{ $t('deviceEdit.sensorConfig') }}</strong>
            <hr>
            <br>
            <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Setup"]["Sensors Config"].indexOf(key) > -1'
                       :param-key="key" :param-value="value"/>
        </div>

        <div v-if="shouldShow('Datetime')">
            <br><strong>{{ $t('deviceEdit.datetime') }}</strong>
            <hr>
            <br>
            <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Setup"]["Datetime"].indexOf(key) > -1' :param-key="key"
                       :param-value="value"/>
        </div>

        <div v-if="shouldShow('Holiday')">
            <br><strong>{{ $t('deviceEdit.holiday') }}</strong>
            <hr>
            <br>
            <Parameter v-for="(value, key) in parameters" :key="key"
                       v-if='categoriesLookup["Setup"]["Holiday"].indexOf(key) > -1 && shouldRenderHolidayParameter(key)' :param-key="key"
                       :param-value="value"/>
        </div>

        <div v-if="!isUnlinked && (userPrivilege === 2 || userPrivilege === 3)" class="mt-5">
            <device-templates></device-templates>
        </div>

        <div v-if="variant == '2' && !isUnlinked && (userPrivilege === 2 || userPrivilege === 3)" class="mt-5">
            <reset-energy-data></reset-energy-data>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import DeviceTemplates from "@/components/virtual-device/edit/device-templates/DeviceTemplates";
    import ResetEnergyData from "./ResetEnergyData";
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

    export default {
        name: "Setup",

        components: {
            Parameter,
            DeviceTemplates,
            ResetEnergyData
        },

        props: [
            'parameters',
            'categoriesLookup',
            'shouldShow'
        ],

        computed: {
            ...mapGetters({
                getUserPrivilege: 'virtualDevice/userPrivilege',
                getUnlinked: 'virtualDevice/unlinked',
            }),

            userPrivilege() {
                return this.getUserPrivilege(this.$route.params.id);
            },

            isUnlinked: function () {
                return this.getUnlinked(this.$route.params.id);
            },

            variant() {
                return this.parameters['p_1'].value;
            },
        },

        methods: {
            shouldRenderHolidayParameter(key) {
                return key === 'p_235' || this.parameters['p_235'].value == 1;
            }
        }
    }
</script>

<style scoped>

</style>