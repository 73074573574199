<template>
    <div>
        <strong>{{ $t('deviceEdit.manageTemplates') }}</strong>
        <hr>
        <b-col cols="12" sm="12" md="7" lg="6" xl="5" class="p-0">
            <p v-if="uiVersion === 'tdc_v0' || uiVersion ==='tdc_v1' || uiVersion === 'tdc_v2'">
                {{ $t('deviceEdit.manageTemplatesDescV0') }}
            </p>
            <p v-else>
                {{ $t('deviceEdit.manageTemplatesDescV1') }}
            </p>
        </b-col>

        <b-button variant="primary" @click="$root.$emit('show-save-modal')">{{ $t('deviceEdit.save') }}</b-button>
        <b-button v-if="uiVersion !== 'tdc_v0' && uiVersion !=='tdc_v1' && uiVersion !== 'tdc_v2'" variant="primary"
                  @click="$root.$emit('show-load-modal')" class="ml-3">{{ $t('deviceEdit.load') }}
        </b-button>

        <save-template></save-template>
        <load-template v-if="uiVersion !== 'tdc_v0' && uiVersion !=='tdc_v1' && uiVersion !== 'tdc_v2'"></load-template>
    </div>
</template>

<script>
    import SaveTemplate from './SaveTemplate'
    import LoadTemplate from './LoadTemplate'
    import {mapGetters} from 'vuex';

    export default {
        name: "DeviceTemplates",

        components: {
            SaveTemplate,
            LoadTemplate
        },

        computed: {
            ...mapGetters({
                getUiVersion: 'virtualDevice/uiVersion'
            }),

            uiVersion: function () {
                return this.getUiVersion(this.$route.params.id);
            }
        }
    }
</script>

<style scoped>

</style>