<template>
    <div>
        <b-modal ref="viewTemplateModal" size="xl" :title="openedTemplateName" ok-only>
            <b-table id="my-table" :items="items" :fields="fields" :per-page="perPage" :current-page="currentPage" :borderless="true" striped small>
                <span slot="value" slot-scope="data" v-html="data.value"/>
            </b-table>

            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"/>
        </b-modal>
    </div>
</template>

<script>
    import {valueFormatter} from "@/utilities/parameter.utility";

    export default {
        name: "ViewTemplateModal",

        data() {
            return {
                openedTemplateName: "Template Parameters",
                perPage: 20,
                currentPage: 1,
                items: [],
                fields: [{key: 'parameter', label: this.$t('templates.parameter')}, {key: 'value', label: this.$t('templates.value')}]
            }
        },

        computed: {
            rows() {
                return this.items.length
            }
        },

        mounted() {
            this.$root.$on('show-template-info', this.viewTemplate);
        },

        destroyed() {
            this.$root.$off('show-template-info');
        },

        methods: {
            viewTemplate(template) {
                let templateData = JSON.parse(template.content);
                this.openedTemplateName = template.name;
                this.items = this.formatTemplateData(templateData, template.template_version);

                this.$refs.viewTemplateModal.show()
            },

            formatTemplateData(templateData, templateVersion) {
                let formattedData = [];
                let p78 = templateData['p_78'] ? templateData['p_78'].value : 0;
                let p81 = templateData['p_81'] ? templateData['p_81'].value : 0;
                let p84 = templateData['p_84'] ? templateData['p_84'].value : 0;
                let p142 = templateData['p_142'] ? templateData['p_142'].value : 0;
                let p186 = templateData['p_186'] ? templateData['p_186'].value : 0;

                for (let key in templateData) {
                    let data = templateData[key];

                    if (key === 'p_71' && templateVersion === 1) {
                        let value = data.value;
                        value = value > 0 ? "UTC+" + value : "UTC" + value;

                        formattedData.push({
                            parameter: data.long_name,
                            value: value
                        });
                    } else {
                        formattedData.push({
                            parameter: data.long_name,
                            value: valueFormatter(key, data.value, p78, p81, p84, p142, p186)
                        });
                    }
                }

                return formattedData;
            }
        }
    }
</script>

<style scoped>

</style>