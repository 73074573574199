<template>
    <div class="warning" v-show="showWarning">
        <b-btn :id="'warning_' + labelId" class="device-button"
               style="color: white; background-color: white; border-color: white; padding: 0; margin: 0; max-height: 20px;">
            <i style="color: orange; font-size: 20px; padding: 0;" class="fa fa-warning"></i>
        </b-btn>
        <b-tooltip :target="'warning_' + labelId" title="Communication error, check if device is online." placement="left" offset=-1.25
                   triggers="hover focus" @hidden="tooltipHidden"></b-tooltip>
    </div>
</template>

<script>
    export default {
        name: "ScheduleWarningLabel",

        props: [
            'labelId',
            'showWarning'
        ],

        methods: {
            tooltipHidden() {
                this.$emit('update:showWarning', false);
            }
        }
    }
</script>

<style scoped>

</style>