<template>
    <div>
        <b-row>
            <b-col cols="11">
                <div ref="visualization"></div>
            </b-col>
            <b-col cols="1">
                <b-row style="padding-top: 42px">
                    <b-col cols="12" style="padding-bottom: 23px; min-height: 43px; max-height: 43px;">
                        <span v-show="!pendingInterval[0] && !showWarning[0]" class="icon icon-docs copy-day" @click="showCopyDayModal(0)"></span>

                        <spinner v-show="pendingInterval[0]" size="20" class="float-left"></spinner>

                        <schedule-warning-label label-id="0" v-bind:show-warning.sync="showWarning[0]"></schedule-warning-label>
                    </b-col>
                    <b-col cols="12" style="padding-bottom: 23px; min-height: 43px; max-height: 43px;">
                        <span v-show="!pendingInterval[1] && !showWarning[1]" class="icon icon-docs copy-day" @click="showCopyDayModal(1)"></span>

                        <spinner v-show="pendingInterval[1]" size="20" class="float-left"></spinner>

                        <schedule-warning-label label-id="1" v-bind:show-warning.sync="showWarning[1]"></schedule-warning-label>
                    </b-col>
                    <b-col cols="12" style="padding-bottom: 23px; min-height: 43px; max-height: 43px;">
                        <span v-show="!pendingInterval[2] && !showWarning[2]" class="icon icon-docs copy-day" @click="showCopyDayModal(2)"></span>

                        <spinner v-show="pendingInterval[2]" size="20" class="float-left"></spinner>

                        <schedule-warning-label label-id="2" v-bind:show-warning.sync="showWarning[2]"></schedule-warning-label>
                    </b-col>
                    <b-col cols="12" style="padding-bottom: 23px; min-height: 43px; max-height: 43px;">
                        <span v-show="!pendingInterval[3] && !showWarning[3]" class="icon icon-docs copy-day" @click="showCopyDayModal(3)"></span>

                        <spinner v-show="pendingInterval[3]" size="20" class="float-left"></spinner>

                        <schedule-warning-label label-id="3" v-bind:show-warning.sync="showWarning[3]"></schedule-warning-label>
                    </b-col>
                    <b-col cols="12" style="padding-bottom: 23px; min-height: 43px; max-height: 43px;">
                        <span v-show="!pendingInterval[4] && !showWarning[4]" class="icon icon-docs copy-day" @click="showCopyDayModal(4)"></span>

                        <spinner v-show="pendingInterval[4]" size="20" class="float-left"></spinner>

                        <schedule-warning-label label-id="4" v-bind:show-warning.sync="showWarning[4]"></schedule-warning-label>
                    </b-col>
                    <b-col cols="12" style="padding-bottom: 23px; min-height: 43px; max-height: 43px;">
                        <span v-show="!pendingInterval[5] && !showWarning[5]" class="icon icon-docs copy-day" @click="showCopyDayModal(5)"></span>

                        <spinner v-show="pendingInterval[5]" size="20" class="float-left"></spinner>

                        <schedule-warning-label label-id="5" v-bind:show-warning.sync="showWarning[5]"></schedule-warning-label>
                    </b-col>
                    <b-col cols="12">
                        <span v-show="!pendingInterval[6] && !showWarning[6]" class="icon icon-docs copy-day" @click="showCopyDayModal(6)"></span>

                        <spinner v-if="pendingInterval[6]" size="20" class="float-left"></spinner>

                        <schedule-warning-label label-id="6" v-bind:show-warning.sync="showWarning[6]"></schedule-warning-label>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-modal id="info" ref="infoModal" title="Info" ok-only>
            You cannot add more than 6 intervals on the same day.
        </b-modal>

        <b-modal id="editDesktop" ref="editModalDesktop" title="Edit Interval" return-focus="false" hide-footer>
            <form @submit.stop.prevent>
                <b-row>
                    <b-col cols="10" class="pt-1 pb-4">
                        {{ daysOfWeek[currentDay - 1].text }} {{ startTime }} - {{ endTime }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="!isTypeOnOff" cols="10" class="py-3">
                        <vue-slider :lazy="true" :tooltip="intervalSwitch == false ? 'none' : 'always'" :disabled="intervalSwitch == false" :min="-15"
                                    :max="80" v-model="intervalTemperature" :process-style="{backgroundColor: 'var(--primary)'}"
                                    :tooltip-style="{backgroundColor: 'var(--primary)'}"></vue-slider>
                    </b-col>
                    <b-col cols="2" class="py-2 d-flex align-items-center">
                        <c-switch class="mx-auto" color="primary" label outline="alt" v-model="intervalSwitchProp"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="pt-2">
                        <b-btn variant="primary" @click="addInterval">
                            Split interval
                        </b-btn>

                        <b-btn class="ml-3" variant="danger" @click="deleteInterval">
                            Delete interval
                        </b-btn>
                    </b-col>
                </b-row>
            </form>
        </b-modal>

        <b-modal id="copyInterval" ref="copyIntervalDesktop" :title="dayToCopy" ok-title="Finish" @ok="onCopyModalConfirm" return-focus="false">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Copy this day to:">
                        <b-form-checkbox-group :id="'copy-interval-' + moduleNumber" v-model="formSelectedDays" :options="formSelectedOptions">
                        </b-form-checkbox-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import {fieldsToValue, parseTime, setSchedulesWatchers, temp2Color, valueToFields} from '@/utilities/interval.utility';
    import {mapGetters} from 'vuex';
    import {DataSet, Timeline} from 'vis-timeline';
    import 'vis-timeline/dist/vis-timeline-graph2d.min.css';
    import {Switch as cSwitch} from '@coreui/vue'
    import vueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/default.css';
    import Spinner from 'vue-simple-spinner';
    import ScheduleWarningLabel from './ScheduleWarningLabel';

    export default {
        name: "DesktopSchedule",

        components: {
            cSwitch,
            vueSlider,
            Spinner,
            ScheduleWarningLabel
        },

        props: [
            "moduleNumber"
        ],

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
                getUnlinked: 'virtualDevice/unlinked'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            isTypeOnOff: function () {
                if (this.moduleNumber === 0) {
                    return true; //Heatpump is same as On/Off
                } else if (this.moduleNumber === 1) {
                    return parseInt(this.parameters.p_78.value) === 3;
                } else if (this.moduleNumber === 2) {
                    return parseInt(this.parameters.p_81.value) === 3;
                } else if (this.moduleNumber === 3) {
                    return parseInt(this.parameters.p_84.value) === 3;
                }
            },

            isUnlinked: function () {
                return this.getUnlinked(this.$route.params.id);
            },

            intervalTemperature: {
                get() {
                    if (this.temperature === "Off") return 15;
                    else return this.temperature;
                },

                set(value) {
                    this.updateIntervalTemperature(value);
                }
            },

            intervalSwitchProp: {
                get() {
                    return this.intervalSwitch;
                },

                set(value) {
                    if (this.$refs.editModalDesktop.isShow) { //prevent triggering update when setting the initial value for the switch
                        if (value === true) {
                            let selectedItem = this.items.get(this.selectedId);
                            this.temperature = selectedItem.content;
                            this.temperature = this.temperature.split('°')[0]; //Display interval current temperature

                            if (this.temperature === '' || this.temperature === "Off") {
                                this.temperature = 15;
                            }
                        } else {
                            this.temperature = '';
                        }

                        this.intervalSwitch = value;
                        this.intervalSwitchUpdated();
                    }
                }
            }
        },

        data() {
            return {
                mounted: false, //flag for mounting timeline only once
                LEFT_LIMIT: new Date('2019-01-01 00:00').getTime(), //timeline left limit
                RIGHT_LIMIT: new Date('2019-01-01 24:00').getTime(), //timeline right limit
                THRESHOLD: 900000, //minimum allowed step in timeline
                timeline: null,
                items: null,
                itemsNeighbours: [], //holds relationships between an item and items next to him
                itemsSpan: [], //holds last start time and last end time for each item
                itemProperties: null, //event properties for the item that will be created
                temperature: 0, //temperature for the currently selected device
                previousTemp: 0,
                selectedId: null,
                startTime: null,
                endTime: null,
                daysOfWeek: [{value: 1, text: "Monday"}, {value: 2, text: "Tuesday"}, {value: 3, text: "Wednesday"}, {value: 4, text: "Thursday"},
                    {value: 5, text: "Friday"}, {value: 6, text: "Saturday"}, {value: 7, text: "Sunday"}],
                currentDay: 1,
                dayToCopy: '',
                updatedInterval: false,
                intervalSwitch: true,
                moduleTypeUnwatch: null,
                moduleOperationUnwatch: null,
                schedulesUnwatch: null,
                pendingInterval: [false, false, false, false, false, false, false], //flag if device is waiting for update
                showWarning: [false, false, false, false, false, false, false],
                numberOfRetries: [0, 0, 0, 0, 0, 0, 0],
                sentIntervals: [],
                retryIntervals: [],
                formSelectedDays: [],
                formSelectedOptions: []
            }
        },

        created() {
            if (this.moduleNumber === 0) {
                //Do nothing, heatpump does not have an module type
            } else if (this.moduleNumber === 1) {
                this.moduleTypeUnwatch = this.$watch('parameters.p_78', this.typeWatcherFunction, {
                    deep: true
                });
            } else if (this.moduleNumber === 2) {
                this.moduleTypeUnwatch = this.$watch('parameters.p_81', this.typeWatcherFunction, {
                    deep: true
                });
            } else if (this.moduleNumber === 3) {
                this.moduleTypeUnwatch = this.$watch('parameters.p_84', this.typeWatcherFunction, {
                    deep: true
                });
            }

            this.setModuleOperationWatcher();
            this.schedulesUnwatch = setSchedulesWatchers(this);
            this.$root.$on("mount-timeline", this.mountTimeline);
        },

        mounted() {
            this.mountTimeline(this.moduleNumber);
        },

        destroyed() {
            this.$root.$off("mount-timeline");

            if (this.moduleTypeUnwatch !== null) this.moduleTypeUnwatch();
            if (this.moduleOperationUnwatch !== null) this.moduleOperationUnwatch();
            if (this.timeline !== null) this.timeline.destroy();

            for (let index in this.schedulesUnwatch) {
                this.schedulesUnwatch[index]();
            }

            for (let index in this.retryIntervals) {
                if (this.retryIntervals[index]) clearInterval(this.retryIntervals[index]);
            }
        },

        methods: {
            /**
             * Watcher callback when a schedule parameter has changed
             * @param newParam - New parameter object after watch
             * @param oldParam - Old parameter object before watch
             */
            scheduleWatcher(newParam, oldParam) {
                if (!newParam) return; //because schedule params throw errors when schedule operation is set to disabled and the watcher is called afterwards

                let index = this.getDayIndex(newParam.id);
                if ((newParam.value !== oldParam.value) && !this.pendingInterval[index]) {
                    this.updateDay(JSON.parse(newParam.value), index + 1);
                }
            },

            /**
             * Get index of day of week for a given interval parameter
             * @oaram paramId - interval parameter id
             * */
            getDayIndex(paramId) {
                let paramIndexes = {
                    "41": 0, "42": 1, "43": 2, "44": 3, "45": 4, "46": 5, "47": 6,
                    "113": 0, "114": 1, "115": 2, "116": 3, "117": 4, "118": 5, "119": 6,
                    "120": 0, "121": 1, "122": 2, "123": 3, "124": 4, "125": 5, "126": 6,
                    "127": 0, "128": 1, "129": 2, "130": 3, "131": 4, "132": 5, "133": 6
                };
                return paramIndexes[paramId];
            },

            /**
             * Refresh intervals of this day with data received from physical device
             * @param rawIntervals - JSON string which represents the interval
             * @param groupId - the weekday of the interval
             */
            updateDay(rawIntervals, groupId) {
                let items = this.parseIntervals(rawIntervals, groupId);
                let toBeRemoved = this.items.getIds({
                    filter: (item) => {
                        return item.group == groupId;
                    }
                });
                this.items.remove(toBeRemoved);
                this.items.update(items);
                this.updateNeighbours();
                this.updateItemsSpan();
            },

            /**
             * Watches for changes on module operation
             */
            setModuleOperationWatcher() {
                if (this.moduleOperationUnwatch !== null) {
                    this.moduleOperationUnwatch();
                }

                if (this.moduleNumber === 0) {
                    this.moduleOperationUnwatch = this.$watch('parameters.p_68', this.operationWatcherFunction, {
                        deep: true
                    });
                } else if (this.moduleNumber === 1) {
                    let type = parseInt(this.parameters.p_78.value);
                    if (type === 1) { //thermostat
                        this.moduleOperationUnwatch = this.$watch('parameters.p_101', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 2) { //recycle
                        this.moduleOperationUnwatch = this.$watch('parameters.p_103', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 3) { //on/off
                        this.moduleOperationUnwatch = this.$watch('parameters.p_104', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 5) { //fireplace
                        this.moduleOperationUnwatch = this.$watch('parameters.p_187', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 6) { //heat exchange
                        this.moduleOperationUnwatch = this.$watch('parameters.p_205', this.operationWatcherFunction, {
                            deep: true
                        });
                    }
                } else if (this.moduleNumber === 2) {
                    let type = parseInt(this.parameters.p_81.value);
                    if (type === 1) { //thermostat
                        this.moduleOperationUnwatch = this.$watch('parameters.p_105', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 2) { //recycle
                        this.moduleOperationUnwatch = this.$watch('parameters.p_107', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 3) { //on/off
                        this.moduleOperationUnwatch = this.$watch('parameters.p_108', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 5) { //fireplace
                        this.moduleOperationUnwatch = this.$watch('parameters.p_193', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 6) { //heat exchange
                        this.moduleOperationUnwatch = this.$watch('parameters.p_211', this.operationWatcherFunction, {
                            deep: true
                        });
                    }
                } else if (this.moduleNumber === 3) {
                    let type = parseInt(this.parameters.p_84.value);
                    if (type === 1) { //thermostat
                        this.moduleOperationUnwatch = this.$watch('parameters.p_109', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 2) { //recycle
                        this.moduleOperationUnwatch = this.$watch('parameters.p_111', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 3) { //on/off
                        this.moduleOperationUnwatch = this.$watch('parameters.p_112', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 5) { //fireplace
                        this.moduleOperationUnwatch = this.$watch('parameters.p_199', this.operationWatcherFunction, {
                            deep: true
                        });
                    } else if (type === 6) { //heat exchange
                        this.moduleOperationUnwatch = this.$watch('parameters.p_217', this.operationWatcherFunction, {
                            deep: true
                        });
                    }
                }
            },

            /**
             * Callback function when module type is changed
             * @param newParam - New module type value
             * @param oldParam - Old module type value
             */
            typeWatcherFunction(newParam, oldParam) {
                if (newParam.value !== oldParam.value) {
                    this.setModuleOperationWatcher();
                    this.mountTimeline(this.moduleNumber);
                }
            },

            /**
             * Callback function when module operation is changed
             * @param newParam - New module operation value
             * @param oldParam - Old module operation value
             */
            operationWatcherFunction(newParam, oldParam) {
                if (newParam.value !== oldParam.value) {
                    this.mountTimeline(this.moduleNumber);
                }
            },

            /**
             * If timeline is not mounted starts procedures for mounting it
             */
            mountTimeline(moduleNumber) {
                if (this.moduleNumber !== moduleNumber) return;

                let isScheduleOn;

                if (this.moduleNumber === 0) {
                    isScheduleOn = parseInt(this.parameters.p_68.value) === 2;
                } else if (this.moduleNumber === 1 && this.parameters.p_78) {
                    let type = parseInt(this.parameters.p_78.value);
                    if (type === 0) { //disabled
                        isScheduleOn = false;
                    } else if (type === 1 && this.parameters.p_101) { //thermostat
                        isScheduleOn = parseInt(this.parameters.p_101.value) === 2;
                    } else if (type === 2 && this.parameters.p_103) { //recycle
                        isScheduleOn = parseInt(this.parameters.p_103.value) === 2;
                    } else if (type === 3 && this.parameters.p_104) { //on/off
                        isScheduleOn = parseInt(this.parameters.p_104.value) === 2;
                    } else if (type === 5 && this.parameters.p_187) { //fireplace
                        isScheduleOn = parseInt(this.parameters.p_187.value) === 2;
                    } else if (type === 6 && this.parameters.p_205) { //heat exchange
                        isScheduleOn = parseInt(this.parameters.p_205.value) === 2;
                    }
                } else if (this.moduleNumber === 2 && this.parameters.p_81) {
                    let type = parseInt(this.parameters.p_81.value);
                    if (type === 0) { //disabled
                        isScheduleOn = false;
                    } else if (type === 1 && this.parameters.p_105) { //thermostat
                        isScheduleOn = parseInt(this.parameters.p_105.value) === 2;
                    } else if (type === 2 && this.parameters.p_107) { //recycle
                        isScheduleOn = parseInt(this.parameters.p_107.value) === 2;
                    } else if (type === 3 && this.parameters.p_108) { //on/off
                        isScheduleOn = parseInt(this.parameters.p_108.value) === 2;
                    } else if (type === 5 && this.parameters.p_193) { //fireplace
                        isScheduleOn = parseInt(this.parameters.p_193.value) === 2;
                    } else if (type === 6 && this.parameters.p_211) { //heat exchange
                        isScheduleOn = parseInt(this.parameters.p_211.value) === 2;
                    }
                } else if (this.moduleNumber === 3 && this.parameters.p_84) {
                    let type = parseInt(this.parameters.p_84.value);
                    if (type === 0) { //disabled
                        isScheduleOn = false;
                    } else if (type === 1 && this.parameters.p_109) { //thermostat
                        isScheduleOn = parseInt(this.parameters.p_109.value) === 2;
                    } else if (type === 2 && this.parameters.p_111) { //recycle
                        isScheduleOn = parseInt(this.parameters.p_111.value) === 2;
                    } else if (type === 3 && this.parameters.p_112) { //on/off
                        isScheduleOn = parseInt(this.parameters.p_112.value) === 2;
                    } else if (type === 5 && this.parameters.p_199) { //fireplace
                        isScheduleOn = parseInt(this.parameters.p_199.value) === 2;
                    } else if (type === 6 && this.parameters.p_217) { //heat exchange
                        isScheduleOn = parseInt(this.parameters.p_217.value) === 2;
                    }
                }

                if (!this.mounted && isScheduleOn) {
                    this.initTimeline();
                    this.mounted = true;
                    this.updateNeighbours();
                    this.updateItemsSpan();
                } else if (isScheduleOn) {
                    this.items.clear();
                    this.items.update(this.fetchSchedules());
                    this.updateNeighbours();
                    this.updateItemsSpan();
                }
            },

            /**
             * Creates timeline objects and properties
             */
            initTimeline() {
                const container = this.$refs.visualization;
                this.items = new DataSet(this.fetchSchedules());
                let groups = new DataSet([
                    {
                        id: 1,
                        content: 'Mon'
                    }, {
                        id: 2,
                        content: 'Tue'
                    }, {
                        id: 3,
                        content: 'Wed'
                    }, {
                        id: 4,
                        content: 'Thu'
                    }, {
                        id: 5,
                        content: 'Fri'
                    }, {
                        id: 6,
                        content: 'Sat'
                    }, {
                        id: 7,
                        content: 'Sun'
                    }
                ]);

                let options = {
                    orientation: 'top',
                    margin: {
                        item: 10,
                        axis: 10
                    },
                    align: 'center',
                    min: '2019-01-01 00:00',
                    max: '2019-01-02 00:01',
                    start: '2019-01-01 00:00',
                    end: '2019-01-02 00:01',
                    showMajorLabels: false,
                    format: {
                        /*minorLabels: {
                            hour: 'HH'
                        }*/
                        minorLabels: function (date, scale, step) {
                            let hours = date.hours();

                            if (hours == 0) {
                                hours = "0000"
                            } else if (hours < 10) {
                                hours = "0" + hours;
                            }

                            return hours;
                        },
                    },
                    stack: false,
                    snap: this.snap,
                    zoomable: false,
                    moveable: false,
                    editable: {
                        add: true,         // add new items by double tapping
                        updateTime: true,  // drag items horizontally
                        updateGroup: false, // drag items from one group to another
                        remove: false,       // delete an item by tapping the delete button top right
                        overrideItems: false  // allow these options to override item.editable
                    },
                    itemsAlwaysDraggable: {
                        item: true,
                        range: true
                    },
                    onAdd: (item, callback) => { //disables default double click behaviour on timeline
                        callback(null)
                    },
                    onMoving: this.onMoving,
                    onMove: this.onMove,
                    tooltip: {
                        followMouse: true,
                        overflowMethod: 'cap',
                        template: function (item) {
                            let startHour = item.start.getHours();
                            startHour = startHour < 10 ? "0" + startHour : startHour;
                            let startMinutes = item.start.getMinutes();
                            startMinutes = startMinutes < 10 ? "0" + startMinutes : startMinutes;
                            let endHour = item.end.getHours();
                            endHour = endHour < 10 ? "0" + endHour : endHour;
                            let endMinutes = item.end.getMinutes();
                            endMinutes = endMinutes < 10 ? "0" + endMinutes : endMinutes;

                            return startHour + ':' + startMinutes + ' - ' + endHour + ':' + endMinutes
                        }
                    },
                };

                this.timeline = new Timeline(container, this.items, groups, options);
                this.timeline.on('select', this.onSelect);

                this.updateNeighbours();
                this.updateItemsSpan();
            },

            /**
             * Makes timeline step equal to 15 minutes
             * @param date - date given from timeline
             * @param scale
             * @param step
             * @returns {*} - formatted date
             */
            snap(date, scale, step) {
                let minute = date.getMinutes();
                let hour = date.getHours();
                if (minute < 15) {
                    minute = 15;
                } else if (minute < 30) {
                    minute = 30;
                } else if (minute < 45) {
                    minute = 45;
                } else if (minute < 60) {
                    minute = 0;
                    hour += 1;
                }

                date.setSeconds(0);
                date.setMinutes(minute);
                date.setHours(hour);

                return date;
            },

            /**
             * Callback function when timeline is clicked, opens edit modal if an interval is clicked
             * @param properties - click event properties
             */
            onSelect(properties) {
                if (this.isUnlinked) return;

                if (properties.event.type === "tap" && properties.items.length !== 0) {
                    this.selectedId = properties.items[0];
                    let selectedItem = this.items.get(this.selectedId);
                    this.temperature = selectedItem.content;
                    this.temperature = this.temperature.split('°')[0];
                    // this.previousTemp = this.temperature;
                    this.intervalSwitch = !(this.temperature === "Off" || this.temperature === 999);
                    this.startTime = selectedItem.start;
                    let hours = this.startTime.getHours();
                    if (hours < 10) hours = "0" + hours;
                    let minutes = this.startTime.getMinutes();
                    if (minutes < 10) minutes = "0" + minutes;
                    this.startTime = hours + ":" + minutes;

                    this.endTime = selectedItem.end;
                    minutes = this.endTime.getMinutes();
                    if (minutes < 10) minutes = "0" + minutes;
                    hours = this.endTime.getHours();
                    if (hours === 0 && minutes === "00") hours = "24";
                    else if (hours < 10) hours = "0" + hours;
                    this.endTime = hours + ":" + minutes;

                    this.currentDay = selectedItem.group;

                    this.timeline.setSelection([]); //unselect selected item

                    this.$set(this, 'formSelectedDays', []);
                    this.$set(this, 'formSelectedOptions', []);
                    this.$set(this, 'formSelectedOptions', [
                        {value: '1', text: 'Mon'},
                        {value: '2', text: 'Tue'},
                        {value: '3', text: 'Wed'},
                        {value: '4', text: 'Thu'},
                        {value: '5', text: 'Fri'},
                        {value: '6', text: 'Sat'},
                        {value: '7', text: 'Sun'}
                    ]);
                    this.formSelectedOptions.splice(this.currentDay - 1, 1);
                    this.$refs.editModalDesktop.show();
                }
            },

            /**
             * Callback function while moving a timeline item. Here is implemented all expand and shrink functionality of intervals
             * @param item - timeline item which we consider as interval
             * @param callback - vis.js callback
             */
            onMoving(item, callback) {
                this.updateItemsSpan();
                this.currentDay = item.group;

                if (this.isMovingEntireItem(item)) { //If is trying to move entire item don't let him, only dragging one side is allowed
                    // console.log("Trying to move entire item...");

                    item.start = this.itemsSpan[item.id].start;
                    item.end = this.itemsSpan[item.id].end;
                    this.updatedInterval = false;
                } else if (this.isTryingToDragLimits(item)) { //If is trying to drag on timeline limits don't let him
                    // console.log("Trying to drag limits...");

                    if (this.itemsSpan[item.id].start.getTime() === this.LEFT_LIMIT) {
                        item.start = this.itemsSpan[item.id].start; //reset left limit
                    }
                    if (this.itemsSpan[item.id].end.getTime() === this.RIGHT_LIMIT) {
                        item.end = this.itemsSpan[item.id].end; //reset right limit
                    }
                    this.updatedInterval = false;
                } else {
                    let rightNeighbour = this.itemsNeighbours[item.id].rightNeighbour;
                    let leftNeighbour = this.itemsNeighbours[item.id].leftNeighbour;

                    if (this.isNewRangeValid(item.start.getTime(), item.end.getTime())) { //If current item is not shrinking more than allowed
                        if (leftNeighbour !== null) {
                            // console.log("Moving left neighbour...");

                            // Check if new range is also valid for neighbour
                            if (this.isNewRangeValid(this.itemsSpan[leftNeighbour.id].start.getTime(), item.start.getTime())) {
                                leftNeighbour.start = this.itemsSpan[leftNeighbour.id].start;
                                leftNeighbour.end = item.start;
                                this.items.update(leftNeighbour);
                                this.updatedInterval = true;
                            } else {
                                leftNeighbour.end = new Date(leftNeighbour.start.getTime() + this.THRESHOLD);
                                this.items.update(leftNeighbour);
                                item.start = leftNeighbour.end;
                            }
                        }

                        if (rightNeighbour !== null) {
                            // console.log("Moving right neighbour...");

                            // Check if new range is also valid for neighbour
                            if (this.isNewRangeValid(item.end.getTime(), this.itemsSpan[rightNeighbour.id].end.getTime())) {
                                rightNeighbour.start = item.end;
                                rightNeighbour.end = this.itemsSpan[rightNeighbour.id].end;
                                this.items.update(rightNeighbour);
                                this.updatedInterval = true;
                            } else {
                                rightNeighbour.start = new Date(rightNeighbour.end.getTime() - this.THRESHOLD);
                                this.items.update(rightNeighbour);
                                item.end = rightNeighbour.start;
                            }
                        }
                    } else { //Don't let item shrink more than allowed
                        // console.log("Invalid range...");

                        if (this.isShrinkingFromRight(item)) {
                            // console.log("Dragging right..");

                            item.start = this.itemsSpan[item.id].start;
                            item.end = new Date(item.start.getTime() + this.THRESHOLD);
                            rightNeighbour.start = item.end;
                            this.items.update(rightNeighbour);
                        } else {
                            // console.log("Dragging left..");

                            item.start = new Date(item.end.getTime() - this.THRESHOLD);
                            item.end = new Date(item.end.getTime());
                            leftNeighbour.end = item.start;
                            this.items.update(leftNeighbour);
                        }
                    }

                }

                callback(item);
            },

            /**
             * Callback function when finishing moving a interval. Sends interval update to device
             * @param item - timeline item which we consider as interval
             * @param callback - vis.js callback
             */
            onMove(item, callback) {
                // console.log("Move finished");
                callback(item); //if callback has not been called the item is not updated in the timeline dataset
                this.updateItemsSpan();
                this.updateNeighbours();
                if (this.updatedInterval) {
                    this.updatedInterval = false;
                    this.sendDayToDevice();
                }
            },

            /**
             * Updates itemsNeighbours which is an array which contains intervals and the intervals to the right and left of them.
             */
            updateNeighbours() {
                let items = this.items.get();
                for (let key in items) {
                    let item = items[key];

                    //Get items next to this one, ignore milliseconds difference
                    let itemStart = this.roundMillis(item.start.getTime());
                    let itemEnd = this.roundMillis(item.end.getTime());

                    let neighbourItems = this.items.get({
                        filter: (otherItem) => {
                            let otherItemStart = this.roundMillis(otherItem.start.getTime());
                            let otherItemEnd = this.roundMillis(otherItem.end.getTime());

                            return otherItem.id !== item.id && otherItem.group === item.group &&
                                ((itemEnd - otherItemStart > -this.THRESHOLD && itemEnd - otherItemStart < this.THRESHOLD)
                                    || (otherItemEnd - itemStart > -this.THRESHOLD && otherItemEnd - itemStart < this.THRESHOLD));
                        }
                    });

                    if (neighbourItems.length > 0) {
                        //Check if item is a neighbour on the left side or on the right side
                        let leftNeighbour = null;
                        let rightNeighbour = null;

                        let neighbour = neighbourItems[0];
                        let neighbourStart = this.roundMillis(neighbour.start.getTime());
                        let neighbourEnd = this.roundMillis(neighbour.end.getTime());

                        if (itemEnd - neighbourStart > -this.THRESHOLD && itemEnd - neighbourStart < this.THRESHOLD) {
                            rightNeighbour = neighbour;

                            if (neighbourItems.length === 2) {
                                leftNeighbour = neighbourItems[1]; //the other one must be a left neighbour;
                            }
                        } else if (neighbourEnd - itemStart > -this.THRESHOLD && neighbourEnd - itemStart < this.THRESHOLD) {
                            leftNeighbour = neighbour;

                            if (neighbourItems.length === 2) {
                                rightNeighbour = neighbourItems[1]; //the other one must be a right neighbour;
                            }
                        }

                        this.itemsNeighbours[item.id] = {
                            leftNeighbour: leftNeighbour,
                            rightNeighbour: rightNeighbour
                        };
                    }
                }
            },

            /**
             * Updates itemsSpan which is an array which holds objects containing the start and end of each interval
             */
            updateItemsSpan() {
                let items = this.items.get();
                for (let key in items) {
                    let item = items[key];
                    this.itemsSpan[item.id] = {
                        start: item.start,
                        end: item.end
                    }
                }
            },

            /**
             * Checks if user is trying to move an entire interval or just its sides
             * @param item - the interval the user is trying to move
             * @returns {boolean} - true if user is trying to move entire interval, false otherwise
             */
            isMovingEntireItem(item) {
                return this.roundMillis(item.start.getTime()) !== this.roundMillis(this.itemsSpan[item.id].start.getTime())
                    && this.roundMillis(item.end.getTime()) !== this.roundMillis(this.itemsSpan[item.id].end.getTime());
            },

            /**
             * Checks if user is trying to move the side of the interval starting at 00:00 or the side ending on 24:00
             * @param item - the interval the user is trying to move
             * @returns {boolean} - true if the user is trying to move this limits, false otherwise
             */
            isTryingToDragLimits(item) {
                let leftLimit = this.itemsSpan[item.id].start.getTime();
                let rightLimit = this.itemsSpan[item.id].end.getTime();

                // console.log(rightLimit)

                return (this.roundMillis(leftLimit) === this.LEFT_LIMIT && this.roundMillis(leftLimit) !== this.roundMillis(item.start.getTime()))
                    || (this.roundMillis(rightLimit) === this.RIGHT_LIMIT && this.roundMillis(rightLimit) !== this.roundMillis(item.end.getTime()));
            },

            /**
             * Check if an interval is getting shrinked from its right side
             * @param item - the interval
             * @returns {boolean} - true if getting shrinked from right side, false otherwise
             */
            isShrinkingFromRight(item) {
                return this.roundMillis(item.end.getTime()) !== this.roundMillis(this.itemsSpan[item.id].end.getTime());
            },

            /**
             * Checks if interval is long enough
             * @param startTime - interval start
             * @param endTime
             * @returns {boolean}
             */
            isNewRangeValid(startTime, endTime) {
                return this.roundMillis(endTime) - this.roundMillis(startTime) >= this.THRESHOLD;
            },

            /**
             * Formats content of intervals
             * @param temp - interval setpoint
             * @returns {string} - returns on, off or interval temperature
             */
            formatContent(temp) {
                if (this.isTypeOnOff) {
                    return (temp === "Off" || temp === 999 || temp === '') ? "Off" : "On";
                } else {
                    return (temp === "Off" || temp === 999 || temp === '') ? "Off" : temp + '°';
                }
            },

            /**
             * Formats interval background color
             * @param temp - interval setpoint
             * @returns {string} - returns interval background style
             */
            formatBackground(temp) {
                if (this.isTypeOnOff) {
                    return (temp === "Off" || temp === 999 || temp === '') ? "background: rgb(220, 220, 220)" : "background: rgb(244, 185, 66)";
                } else {
                    return (temp === "Off" || temp === 999 || temp === '') ? "background: rgb(220, 220, 220)" : "background: " + temp2Color(temp);
                }
            },

            /**
             * Splits the old interval in two new intervals
             */
            addInterval() {
                //check if there are less than 6 items on clicked group
                let ids = this.items.getIds({
                    filter: (item) => {
                        return item.group === this.currentDay;
                    }
                });

                if (ids.length >= 6) {
                    this.$refs.infoModal.show();
                    return;
                }

                let item = this.items.get(this.selectedId);

                //If large enough get split in half
                if (this.roundMillis(item.end.getTime()) - this.roundMillis(item.start.getTime()) >= 2 * this.THRESHOLD) {
                    let midDate = new Date('2019-01-01 00:00');
                    let totalInMinutes = ((item.end.getDate() - item.start.getDate()) * 1440 +
                        (item.end.getHours() * 60 + item.end.getMinutes()) + (item.start.getHours() * 60 + item.start.getMinutes())) / 2;
                    let hours = totalInMinutes / 60;
                    let minutes = Math.round((totalInMinutes % 60) / 15) * 15;
                    midDate.setHours(hours);
                    midDate.setMinutes(minutes);

                    let item1 = {
                        id: new Date().getTime() + 1,
                        group: item.group,
                        content: this.formatContent(this.temperature),
                        type: 'range',
                        start: item.start,
                        end: midDate,
                        style: this.formatBackground(this.temperature)
                    };

                    let item2 = {
                        id: new Date().getTime() + 2,
                        group: item.group,
                        content: this.formatContent(this.temperature),
                        type: 'range',
                        start: midDate,
                        end: item.end,
                        style: this.formatBackground(this.temperature)
                    };

                    this.items.update(item1);
                    this.items.update(item2);
                    this.items.remove(item.id);

                    this.updateItemsSpan();
                    this.updateNeighbours();
                    this.sendDayToDevice();
                    this.$refs.editModalDesktop.hide();
                }
            },

            intervalSwitchUpdated() {
                let start = new Date('2019-01-01 ' + this.startTime);
                let end = new Date('2019-01-01 ' + this.endTime);
                let content;
                let background;

                if (this.intervalSwitch === false) {
                    content = "Off";
                    background = "background: rgb(220,220,220)";
                } else {
                    content = this.formatContent(this.temperature);
                    background = this.formatBackground(this.temperature);
                }

                this.items.update({
                    id: this.selectedId, group: this.currentDay, content: content, start: start, end: end,
                    style: background
                });

                this.sendDayToDevice();
            },

            updateIntervalTemperature(temperature) {
                this.previousTemp = this.temperature;
                this.temperature = temperature;

                let start = new Date('2019-01-01 ' + this.startTime);
                let end = new Date('2019-01-01 ' + this.endTime);
                let content;
                let background;

                if (this.intervalSwitch === false) {
                    content = "Off";
                    background = "background: rgb(220,220,220)";
                } else {
                    content = this.formatContent(this.temperature);
                    background = this.formatBackground(this.temperature);
                }

                if (this.items.get(this.selectedId) === null) { //can be null when edit modal has been opened and the interval are updated by the physical device
                    let items = this.items.get({
                        filter: (item) => {
                            return item.group === this.currentDay;
                        }
                    });

                    for (let i = 0; i < items.length; i++) { //we are iterating the new list of items so we can find the item with the new id
                        if (items[i].start.toString() == new Date('2019-01-01 ' + this.startTime).toString()
                            && items[i].end.toString() == new Date('2019-01-01 ' + this.endTime).toString()) {
                            this.selectedId = items[i].id;
                        }
                    }

                    if (this.items.get(this.selectedId) === null) { //if still could not find an item something unexpected has happened so close the modal and return
                        this.$refs.editModalDesktop.hide();
                        return;
                    }
                }

                let ids = this.items.update({
                    id: this.selectedId, group: this.currentDay, content: content, start: start, end: end,
                    style: background
                });

                this.updateNeighbours();

                //The second condition is needed because the temperature is formatted as empty when shown on edit modal input field
                if (this.temperature != this.previousTemp && (this.previousTemp !== 'Off' && this.temperature !== '')) {
                    this.sendDayToDevice();
                }
            },

            /**
             * Deletes current selected interval, updates neighbours start end times and sends changes to physical device
             */
            deleteInterval() {
                let ids = this.items.getIds({
                    filter: (item) => {
                        return item.group === this.currentDay;
                    }
                });

                if (ids.length <= 1) {
                    return; //cannot delete an interval when it is the only one
                }

                let leftNeighbour = this.itemsNeighbours[this.selectedId].leftNeighbour;
                if (leftNeighbour !== null) {
                    leftNeighbour.end = this.items.get(this.selectedId).end;
                    this.items.update(leftNeighbour);
                } else {
                    let rightNeighbour = this.itemsNeighbours[this.selectedId].rightNeighbour;
                    rightNeighbour.start = this.items.get(this.selectedId).start;
                    this.items.update(rightNeighbour);
                }

                this.items.remove(this.selectedId);
                this.itemsSpan = [];
                this.updateItemsSpan();
                this.itemsNeighbours = [];
                this.updateNeighbours();
                this.sendDayToDevice();
                this.$refs.editModalDesktop.hide();
            },

            /**
             * Copy the current selected day into another day
             * @param destinationDayId - day which will be overwritten
             */
            copyInterval(destinationDayId) {
                let items = this.items.get({
                    filter: (item) => {
                        return item.group === this.currentDay;
                    }
                });

                let itemsToAdd = items.slice(); //copy array

                let uniqueId = this.getRandomInt();
                for (let key in itemsToAdd) {
                    itemsToAdd[key].id = uniqueId + parseInt(key);
                    itemsToAdd[key].group = destinationDayId;
                }

                let itemsToRemove = this.items.get({
                    filter: (item) => {
                        return parseInt(item.group) === parseInt(destinationDayId);
                    }
                });

                this.items.remove(itemsToRemove);
                this.items.update(itemsToAdd);
                this.updateNeighbours();
                this.updateItemsSpan();
                this.sendDayToDevice(destinationDayId);
            },

            /**
             * Format intervals of the current selected day into a JSON object and send them to the physical device
             */
            sendDayToDevice(day = null) {
                if (day === null) day = this.currentDay;

                let key = "p_";
                if (this.moduleNumber === 0) {
                    key += (41 + parseInt(day) - 1);
                } else if (this.moduleNumber === 1) {
                    key += (113 + parseInt(day) - 1);
                } else if (this.moduleNumber === 2) {
                    key += (120 + parseInt(day) - 1);
                } else if (this.moduleNumber === 3) {
                    key += (127 + parseInt(day) - 1);
                }

                if (this.isUnlinked) {
                    this.updateDay(JSON.parse(this.parameters[key].value), day);
                    return;
                }

                let items = this.items.get({
                    filter: (item) => {
                        return item.group === day;
                    }
                });

                //sort items by start time in ascending order
                items.sort(function (a, b) {
                    return a.start.getTime() - b.start.getTime();
                });

                let parameter = {}, interval = {};
                let item, setpoint, value;
                for (let i = 0; i < items.length; i++) {
                    item = items[i];

                    setpoint = item.content.replace('°', '');
                    if (setpoint === "Off") setpoint = 999;
                    else setpoint = parseInt(setpoint);

                    let startTime = item.start.getHours() + ":" + item.start.getMinutes();
                    value = fieldsToValue(setpoint, startTime);
                    interval[i + 1] = value;
                }

                parameter.id = this.$route.params.id;
                parameter[key] = interval;
                this.updateParameter(parameter, key);

                if (this.retryIntervals[day - 1] !== undefined) {
                    clearInterval(this.retryIntervals[day - 1]);
                }

                this.retryIntervals[day - 1] = setInterval(() => {
                    this.numberOfRetries[day - 1] += 1;

                    if (this.numberOfRetries[day - 1] <= 5) {
                        this.updateParameter(parameter, key);
                    } else {
                        this.$set(this.pendingInterval, day - 1, false);
                        this.$set(this.showWarning, day - 1, true);
                        this.numberOfRetries[day - 1] = 0;
                        clearInterval(this.pendingInterval[day - 1]);
                        clearInterval(this.retryIntervals[day - 1]);
                        this.updateDay(JSON.parse(this.parameters[key].value), day);
                    }
                }, 5000, parameter);

                this.$set(this.showWarning, day - 1, false);
                this.$set(this.pendingInterval, day - 1, true);
                this.sentIntervals[day - 1] = JSON.stringify(interval);
            },

            updateParameter(parameter, key) {
                this.$axios.post('/update-parameter-v2', {
                    data: parameter
                }).then((response) => {
                    let data = response.data;
                    if (data[key] === JSON.stringify(parameter[key])) {
                        let paramId = key.split('p_')[1];
                        let index = this.getDayIndex(paramId);
                        this.sentIntervals[index] = null;
                        this.$set(this.pendingInterval, index.toString(), false);
                        clearInterval(this.retryIntervals[index]);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },

            /**
             * Take schedule parameters from the parameters pool in virtual device store and format them properly
             * @returns {Array}
             */
            fetchSchedules() {
                let items = [];

                if (this.moduleNumber === 0) {
                    let rawIntervals = this.parameters.p_41 !== undefined ? JSON.parse(this.parameters.p_41.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 1));

                    rawIntervals = this.parameters.p_42 !== undefined ? JSON.parse(this.parameters.p_42.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 2));

                    rawIntervals = this.parameters.p_43 !== undefined ? JSON.parse(this.parameters.p_43.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 3));

                    rawIntervals = this.parameters.p_44 !== undefined ? JSON.parse(this.parameters.p_44.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 4));

                    rawIntervals = this.parameters.p_45 !== undefined ? JSON.parse(this.parameters.p_45.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 5));

                    rawIntervals = this.parameters.p_46 !== undefined ? JSON.parse(this.parameters.p_46.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 6));

                    rawIntervals = this.parameters.p_47 !== undefined ? JSON.parse(this.parameters.p_47.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 7));
                } else if (this.moduleNumber === 1) {
                    let rawIntervals = this.parameters.p_113 !== undefined ? JSON.parse(this.parameters.p_113.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 1));

                    rawIntervals = this.parameters.p_114 !== undefined ? JSON.parse(this.parameters.p_114.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 2));

                    rawIntervals = this.parameters.p_115 !== undefined ? JSON.parse(this.parameters.p_115.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 3));

                    rawIntervals = this.parameters.p_116 !== undefined ? JSON.parse(this.parameters.p_116.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 4));

                    rawIntervals = this.parameters.p_117 !== undefined ? JSON.parse(this.parameters.p_117.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 5));

                    rawIntervals = this.parameters.p_118 !== undefined ? JSON.parse(this.parameters.p_118.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 6));

                    rawIntervals = this.parameters.p_119 !== undefined ? JSON.parse(this.parameters.p_119.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 7));
                } else if (this.moduleNumber === 2) {
                    let rawIntervals = this.parameters.p_120 !== undefined ? JSON.parse(this.parameters.p_120.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 1));

                    rawIntervals = this.parameters.p_121 !== undefined ? JSON.parse(this.parameters.p_121.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 2));

                    rawIntervals = this.parameters.p_122 !== undefined ? JSON.parse(this.parameters.p_122.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 3));

                    rawIntervals = this.parameters.p_123 !== undefined ? JSON.parse(this.parameters.p_123.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 4));

                    rawIntervals = this.parameters.p_124 !== undefined ? JSON.parse(this.parameters.p_124.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 5));

                    rawIntervals = this.parameters.p_125 !== undefined ? JSON.parse(this.parameters.p_125.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 6));

                    rawIntervals = this.parameters.p_126 !== undefined ? JSON.parse(this.parameters.p_126.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 7));
                } else if (this.moduleNumber === 3) {
                    let rawIntervals = this.parameters.p_127 !== undefined ? JSON.parse(this.parameters.p_127.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 1));

                    rawIntervals = this.parameters.p_128 !== undefined ? JSON.parse(this.parameters.p_128.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 2));

                    rawIntervals = this.parameters.p_129 !== undefined ? JSON.parse(this.parameters.p_129.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 3));

                    rawIntervals = this.parameters.p_130 !== undefined ? JSON.parse(this.parameters.p_130.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 4));

                    rawIntervals = this.parameters.p_131 !== undefined ? JSON.parse(this.parameters.p_131.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 5));

                    rawIntervals = this.parameters.p_132 !== undefined ? JSON.parse(this.parameters.p_132.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 6));

                    rawIntervals = this.parameters.p_133 !== undefined ? JSON.parse(this.parameters.p_133.value) : [];
                    items = items.concat(this.parseIntervals(rawIntervals, 7));
                }

                return items;
            },

            /**
             * Converts intervals from a JSON string into an array
             * @param rawIntervals - JSON string representing the intervals for a weekday
             * @param groupId - interval item group id in timeline
             * @returns {Array} - intervals formatted as an array
             */
            parseIntervals(rawIntervals, groupId) {
                let items = [];
                let parsedIntervals = [];
                for (let interval in rawIntervals) {
                    parsedIntervals[interval] = valueToFields(rawIntervals[interval]);
                }

                let uniqueId = this.getRandomInt();

                if (parsedIntervals.length === 0) {
                    items.push({
                        id: uniqueId,
                        group: groupId,
                        content: "Off",
                        start: new Date('2019-01-01 00:00'),
                        end: new Date('2019-01-01 24:00'),
                        style: "background: rgb(220, 220, 220)"
                    })
                } else {
                    let temp, startHour, startMin, endHour, endMin, startTime, endTime;
                    for (let key = 1; key < parsedIntervals.length; key++) {
                        temp = parsedIntervals[key][0];
                        startHour = parsedIntervals[key][1];
                        startMin = parsedIntervals[key][2];
                        startTime = parseTime(startHour, startMin);
                        if (key === parsedIntervals.length - 1) {
                            endTime = "24:00";
                        } else {
                            endHour = parsedIntervals[key + 1][1];
                            endMin = parsedIntervals[key + 1][2];
                            endTime = parseTime(endHour, endMin);
                        }

                        items.push({
                            id: uniqueId + key,
                            group: groupId,
                            content: this.formatContent(temp),
                            start: new Date('2019-01-01 ' + startTime),
                            end: new Date('2019-01-01 ' + endTime),
                            style: this.formatBackground(temp)
                        })
                    }

                    return items;
                }
            },

            /**
             * Rounds give time to milliseconds
             * @param unixTime - given time
             * @returns {number} - rounded time
             */
            roundMillis(unixTime) {
                return Math.round(unixTime / 1000) * 1000
            },

            /**
             * Return random number from 0 to 100000 - 1
             *
             * @returns {number}
             */
            getRandomInt() {
                return Math.floor(Math.random() * Math.floor(100000));
            },

            showCopyDayModal(dayToCopy) {
                if (dayToCopy === 0) this.dayToCopy = "Monday";
                else if (dayToCopy === 1) this.dayToCopy = "Tuesday";
                else if (dayToCopy === 2) this.dayToCopy = "Wednesday";
                else if (dayToCopy === 3) this.dayToCopy = "Thursday";
                else if (dayToCopy === 4) this.dayToCopy = "Friday";
                else if (dayToCopy === 5) this.dayToCopy = "Saturday";
                else if (dayToCopy === 6) this.dayToCopy = "Sunday";

                this.$set(this, 'formSelectedDays', []);
                this.$set(this, 'formSelectedOptions', []);
                this.$set(this, 'formSelectedOptions', [
                    {value: '1', text: 'Mon'},
                    {value: '2', text: 'Tue'},
                    {value: '3', text: 'Wed'},
                    {value: '4', text: 'Thu'},
                    {value: '5', text: 'Fri'},
                    {value: '6', text: 'Sat'},
                    {value: '7', text: 'Sun'}
                ]);
                this.formSelectedOptions.splice(dayToCopy, 1);

                this.$refs.copyIntervalDesktop.show();
            },

            onCopyModalConfirm() {
                for (let key in this.formSelectedDays) {
                    this.copyInterval(this.formSelectedDays[key]);
                }

                this.updateNeighbours();
            }
        }
    }
</script>

<style>
    .vis-timeline {
        background: white;
        border: none;
    }

    .vis-time-axis .vis-text {
        padding-left: 0px;
        margin-left: -7px;
    }

    .vis-text.vis-minor.vis-h0.vis-even, .vis-text.vis-minor.vis-h0-h4.vis-even {
        margin-left: -15px;
    }

    div.vis-label.vis-group-level-0 {
        height: 43px !important;
    }

    .vis-labelset .vis-label .vis-inner {
        padding-top: 13px;
        padding-left: 0px;
        padding-right: 15px;
        font-size: 14px;
    }

    .vis-foreground {
        background: white;
    }

    .vis-panel {
        border: none !important;
    }

    .vis-label {
        border: white !important;
    }

    .vis-group {
        background: white;
        border: white !important;
    }

    .vis-item {
        color: white;
        border-color: #20a8d8;
        background-color: #20a8d8;
    }

    .vis-selected {
        color: white;
        border-color: #20a8d8 !important;
    }

    .vis-item.vis-range {
        border-radius: 20px;
    }

    .vis-item .vis-drag-center {
        cursor: pointer !important;
    }

    .vis-drag-right {
        cursor: e-resize !important;
    }

    .vis-drag-left {
        cursor: e-resize !important;
    }

    div.vis-tooltip {
        border-radius: 20px;
        background-color: #f7f7f7;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    }

    .warning .btn:focus, .btn:active {
        outline: none !important;
        box-shadow: none !important;
    }

    .copy-day {
        font-size: 1.35em;
        color: #48a0ff;
    }

    .copy-day:hover {
        font-size: 1.35em;
        color: var(--primary);
    }
</style>
