<template>
    <div>
        <b-modal ref="templateModal" centered header-bg-variant="primary" :ok-title="$t('deviceEdit.load')" :cancel-title="$t('general.cancel')"
                 :ok-disabled="!selected" @ok="loadTemplate(selected)">
            <div slot="modal-header" class="w-100">
                <h2 class="text-center">{{ $t('deviceEdit.loadTemplate') }}</h2>
            </div>

            <p>
                {{ $t('deviceEdit.selectTemplateToLoad') }}
            </p>

            <b-row>
                <b-col cols="9" sm="9">
                    <b-form-select v-model="selected" :options="options"></b-form-select>
                </b-col>
                <b-col cols="2" sm="2">
                    <b-button :disabled="!selected" class="template-button" @click="$root.$emit('show-template-info', selected)" variant="primary">
                        {{ $t('deviceEdit.view') }}
                    </b-button>
                </b-col>
            </b-row>

        </b-modal>

        <view-template-modal></view-template-modal>

        <b-modal ref="progressModal" centered header-bg-variant="primary" ok-only ok-title="Interrupt" no-close-on-esc no-close-on-backdrop
                 @ok="cancelLoading()">
            <div slot="modal-header" class="w-100">
                <h2 class="text-center">{{ $t('deviceEdit.loadTemplate') }}</h2>
            </div>

            <p>
                {{ $t('deviceEdit.loading') }}
            </p>

            <b-progress :max="numberOfChunks" height="2rem" animated>
                <b-progress-bar :value="animatedValue" :label="`${((animatedValue / numberOfChunks) * 100).toFixed(2)}%`"/>
            </b-progress>

        </b-modal>

        <b-modal ref="successModal" centered header-bg-variant="primary" ok-only>
            <div slot="modal-header" class="w-100">
                <h2 class="text-center">{{ $t('general.success') }}</h2>
            </div>

            <p>
                {{ $t('deviceEdit.parametersLoadedSuccessfully') }}
            </p>

        </b-modal>

        <b-modal ref="failModal" centered header-bg-variant="warning" ok-only>
            <div slot="modal-header" class="w-100">
                <h2 class="text-center">{{ $t('deviceEdit.warning') }}</h2>
            </div>

            <p>
                {{ $t('deviceEdit.followingParametersCouldNotBeSet') }}
            </p>

            <b-table id="failed-params-table" :items="failedParameters" :per-page="perPage" :current-page="currentPage" :borderless="true" striped
                     small>
                <span slot="value" slot-scope="data" v-html="data.value"></span>
            </b-table>

            <b-pagination v-if="rows > perPage" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="failed-params-table"/>
        </b-modal>
    </div>
</template>

<script>
    import ViewTemplateModal from './ViewTemplateModal';
    import {mapGetters} from 'vuex';
    import {
        valueFormatter,
        isReadOnlyV2,
        isHeatPumpInterval,
        isModule1Interval,
        isModule2Interval,
        isModule3Interval
    } from "@/utilities/parameter.utility";

    export default {
        name: "LoadTemplate",

        components: {
            ViewTemplateModal
        },

        data() {
            return {
                loadTemplateSource: null,
                templatesList: [],
                selected: null,
                options: [],
                currentChunk: 0,
                numberOfChunks: 1,
                perPage: 20,
                currentPage: 1,
                failedParameters: [],
                animateInterval: null,
                animatedValue: 0,
                interruptLoading: false
            }
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
                getUiVersion: 'virtualDevice/uiVersion'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            rows() {
                return this.failedParameters.length
            },

            uiVersion: function () {
                return this.getUiVersion(this.$route.params.id);
            }
        },

        async mounted() {
            this.$root.$on('show-load-modal', async () => {
                let response = await this.$axios.get('/templates-list');
                this.templatesList = response.data;
                this.options = [];

                for (let index in this.templatesList) {
                    let isDisabled = !this.isTemplateCompatible(this.templatesList[index].template_version);
                    let isDisabledText = isDisabled ? ` (${this.$t('deviceEdit.notCompatible')})` : "";

                    this.options.push({
                        value: this.templatesList[index],
                        text: this.templatesList[index].name + isDisabledText,
                        disabled: isDisabled
                    })
                }

                this.options.sort(function (a, b) {
                    let v1 = a.disabled ? 0 : 1;
                    let v2 = b.disabled ? 0 : 1;

                    return v2 - v1;
                });

                this.options.unshift({text: this.$t('deviceEdit.notCompatible'), value: '', disabled: true});
                this.selected = '';

                this.$refs.templateModal.show()
            });
        },

        destroyed() {
            this.$root.$off('show-load-modal');
            this.cancelLoading();
        },

        methods: {
            loadTemplate(templateData) {
                this.$refs.progressModal.show();

                let chunksToSend = this.prepareChunks(templateData);
                this.numberOfChunks = chunksToSend.length;
                this.currentChunk = 0;
                this.interruptLoading = false;

                this.sendChunks(chunksToSend);
            },

            async sendChunks(chunksToSend) {
                try {
                    const CancelToken = this.$axios.CancelToken;
                    this.loadTemplateSource = CancelToken.source();
                    this.animateProgressBar();
                    let failedParameters = [];
                    let numberOfRetries = 0;
                    let i = 0;

                    while (i < chunksToSend.length) {
                        let chunkToSend = chunksToSend[i];

                        let result = await this.$axios.post('/update-parameter-v2', {
                            data: chunkToSend,
                            cancelToken: this.loadTemplateSource.token
                        });

                        if (this.interruptLoading) break;

                        let data = result.data;
                        let flag = false;
                        if (data !== 'Timeout' && data !== 'NotSupportedByDeviceConfig') {
                            flag = true;
                            for (let key in data) {
                                if (key === 'unix_time') continue;

                                if (data[key] != chunkToSend[key]) {
                                    flag = false;
                                    failedParameters.push({
                                        parameter: key,
                                        value: data[key]
                                    })
                                }
                            }
                        }

                        if (flag === true || numberOfRetries > 0) {
                            if (data === 'Timeout' || data === 'NotSupportedByDeviceConfig') {
                                for (let key in chunkToSend) {
                                    if (key === 'id') continue;
                                    failedParameters.push({
                                        parameter: key,
                                        value: chunkToSend[key]
                                    })
                                }
                            }

                            i++;
                            this.animatedValue = i;
                            this.currentChunk = i;
                            numberOfRetries = 0;
                        } else {
                            numberOfRetries++;
                        }
                    }

                    if (!this.interruptLoading) this.finishLoadingTemplate(chunksToSend, failedParameters);
                } catch (err) {
                    console.log(err);
                }
            },

            isTemplateCompatible(templateVersion) {
                if (this.uiVersion === 'tdc_v2' && templateVersion == 1) {
                    return true;
                } else if ((this.uiVersion === 'tdc_v3' || this.uiVersion === 'pln_v1'|| this.uiVersion === 'pln_v1_5') && templateVersion == 2) {
                    return true;
                } else {
                    return false;
                }
            },

            prepareChunks(templateData) {
                let deviceId = this.$route.params.id;
                let formattedParameters = this.formatTemplateData(templateData);
                let specialParameters = formattedParameters[0];
                let heatpumpIntervals = formattedParameters[1];
                let module1Intervals = formattedParameters[2];
                let module2Intervals = formattedParameters[3];
                let module3Intervals = formattedParameters[4];
                let parameters = formattedParameters[5];
                let chunksToSend = [];
                let chunk;

                chunksToSend.push({
                    id: deviceId,
                    'p_1': specialParameters[0].value
                });

                if (specialParameters.length > 1) { //Add module parameters as to a chunk if there are any
                    for (let i = 1; i < specialParameters.length; i++) {
                        chunk = {
                            id: deviceId
                        };

                        chunk[specialParameters[i].key] = specialParameters[i].value;
                        chunksToSend.push(chunk);
                    }
                }

                if (heatpumpIntervals.length > 0) {
                    chunk = {
                        id: deviceId
                    };

                    for (let i = 1; i < heatpumpIntervals.length; i++) {
                        chunk[heatpumpIntervals[i].key] = heatpumpIntervals[i].value;
                    }

                    chunksToSend.push(chunk);
                }

                if (module1Intervals.length > 0) {
                    chunk = {
                        id: deviceId
                    };

                    for (let i = 0; i < module1Intervals.length; i++) {
                        chunk[module1Intervals[i].key] = module1Intervals[i].value;
                    }

                    chunksToSend.push(chunk);
                }

                if (module2Intervals.length > 0) {
                    chunk = {
                        id: deviceId
                    };

                    for (let i = 0; i < module2Intervals.length; i++) {
                        chunk[module2Intervals[i].key] = module2Intervals[i].value;
                    }

                    chunksToSend.push(chunk);
                }

                if (module3Intervals.length > 0) {
                    chunk = {
                        id: deviceId
                    };

                    for (let i = 0; i < module3Intervals.length; i++) {
                        chunk[module3Intervals[i].key] = module3Intervals[i].value;
                    }

                    chunksToSend.push(chunk);
                }

                const CHUNK_MAX_SIZE = 500;
                let chunkSize = 0;
                chunk = {
                    id: deviceId
                };

                for (let i = 0; i < parameters.length; i++) {
                    let key = parameters[i].key;

                    if (isReadOnlyV2(key)) {
                        continue;
                    }

                    let value = parameters[i].value.toString();
                    let parameterSize = key.length + value.length + 2; //+2 because of apostrophes, semicolons etc.

                    if (chunkSize + parameterSize > CHUNK_MAX_SIZE) {
                        //Archive this chunk and prepare an other one

                        chunksToSend.push(chunk);
                        chunkSize = 0;
                        chunk = {
                            id: deviceId
                        };
                        chunk[key] = value;
                        chunkSize += parameterSize;
                    } else {
                        chunk[key] = value;
                        chunkSize += parameterSize;
                    }
                }

                if (chunkSize > 0) { //If there are parameters left in the last chunk
                    chunksToSend.push(chunk);
                }

                return chunksToSend;
            },

            formatTemplateData(templateData) {
                let templateContent = JSON.parse(templateData.content);

                let parameters = [];
                let specialParameters = [];
                let heatpumpIntervals = [];
                let module1Intervals = [];
                let module2Intervals = [];
                let module3Intervals = [];
                for (let key in templateContent) {
                    if (key === 'p_1' || key === 'p_78' || key === 'p_81' || key === 'p_84' || key === 'p_181' || key === 'p_186') {
                        specialParameters.push({key: key, value: templateContent[key].value});
                    } else if (isHeatPumpInterval(key)) {
                        heatpumpIntervals.push({key: key, value: templateContent[key].value});
                    } else if (isModule1Interval(key)) {
                        module1Intervals.push({key: key, value: templateContent[key].value});
                    } else if (isModule2Interval(key)) {
                        module2Intervals.push({key: key, value: templateContent[key].value});
                    } else if (isModule3Interval(key)) {
                        module3Intervals.push({key: key, value: templateContent[key].value});
                    } else {
                        parameters.push({key: key, value: templateContent[key].value});
                    }
                }

                return [specialParameters, heatpumpIntervals, module1Intervals, module2Intervals, module3Intervals, parameters];
            },

            finishLoadingTemplate(chunksToSend, failedParameters) {
                let sentParameters = {};
                for (let j = 0; j < chunksToSend.length; j++) {
                    for (let key in chunksToSend[j]) {
                        if (key !== 'id') {
                            sentParameters[key] = chunksToSend[j][key];
                        }
                    }
                }

                this.failedParameters = this.formatFailedParameters(sentParameters, failedParameters);


                if (this.failedParameters.length > 0) {
                    this.$refs.failModal.show();
                } else {
                    this.$refs.successModal.show();
                }

                this.$refs.progressModal.hide();
                clearInterval(this.animateInterval);
                this.loadTemplateSource = null;
                this.selected = null;
            },

            formatFailedParameters(sentParameters, failedParameters) {
                let p_78 = 0, p_81 = 0, p_84 = 0, p_142 = 0;

                if (failedParameters['p_78']) p_78 = failedParameters['p_78'];
                if (failedParameters['p_81']) p_81 = failedParameters['p_81'];
                if (failedParameters['p_84']) p_84 = failedParameters['p_84'];
                if (failedParameters['p_142']) p_142 = failedParameters['p_142'];

                let templateData = JSON.parse(this.selected.content);
                let formattedFailedParameters = [];

                for (let index in failedParameters) {
                    let key = failedParameters[index].parameter;
                    formattedFailedParameters.push({
                        parameter: templateData[key].long_name,
                        value: valueFormatter(key, sentParameters[key], p_78, p_81, p_84, p_142)
                    });
                }

                return formattedFailedParameters;
            },

            animateProgressBar() {
                this.animatedValue = 0;

                this.animateInterval = setInterval(() => {
                    let maxValue = this.currentChunk + 0.9;
                    if (maxValue > this.numberOfChunks) {
                        maxValue = this.numberOfChunks;
                    }

                    if (this.animatedValue <= maxValue) {
                        let valueToAdd = Math.random() / 10;
                        this.animatedValue += valueToAdd;

                        if (this.animatedValue > maxValue) {
                            this.animatedValue = maxValue;
                        }
                    } else {
                        clearInterval(this.animateInterval);
                    }
                }, 200)
            },

            cancelLoading() {
                this.interruptLoading = true;
                this.selected = null;
                this.currentChunk = 0;
                this.failedParameters = [];
                this.animateInterval = null;
                this.animatedValue = 0;

                clearInterval(this.animateInterval);
                if (this.loadTemplateSource) {
                    this.loadTemplateSource.cancel('Operation canceled by the user.');
                }
            }
        }
    }
</script>

<style scoped>

</style>

























