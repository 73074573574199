<template>
    <div>
        <Parameter v-for="(value, key) in parameters" :key="key" :param-key="key" :param-value="value"
                   v-if='categoriesLookup["Manual Operations"].indexOf(key) > -1 && showManualOperationKey({key: key, deviceId: $route.params.id})'>
        </Parameter>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

    export default {
        name: "ManualOperations",

        components: {
            Parameter,
        },

        props: [
            'parameters',
            'categoriesLookup'
        ],

        computed: {
            ...mapGetters({
                showManualOperationKey: 'virtualDevice/showManualOperationKey'
            })
        }
    }
</script>

<style scoped>

</style>